import { useRouter } from 'next/router';
import GhostButton from '../../../common-components/component-library/buttons/GhostButton';
import { colorsKurppa } from '../../../utils/styleguideKurppa';
import { TypographyVariants } from '../../../common-components/component-library/text/Typography';

export default function Button({
  label,
  step,
  email,
  textVariant,
}: {
  label: string;
  step: 'login' | 'sign-up';
  email?: string;
  textVariant: TypographyVariants;
}) {
  const router = useRouter();
  return (
    <GhostButton
      href={{
        pathname: router.pathname,
        query: {
          ...router.query,
          step,
          email,
        },
      }}
      label={label}
      hideUnderline
      color={colorsKurppa.purple400}
      textVariant={textVariant}
    />
  );
}
