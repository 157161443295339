import styled from '@emotion/styled';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { EVENT_NAMES, sendEvent } from '../../utils/analytics/analytics';
import useOnMountEffect from '../../utils/hooks/useOnMountEffect';
import {
  Col,
  Container,
  Row,
} from '../../common-components/component-library/layout/12ColGridLayout';
import Typography from '../../common-components/component-library/text/Typography';
import ErrorBox from '../../common-components/component-library/ErrorBox';
import PrimaryButton from '../../common-components/component-library/buttons/PrimaryButton';
import Paper from '../../common-components/component-library/Paper';
import SimpleModal from '../../common-components/component-library/modals/SimpleModal';
import FeedbackBox from '../../common-components/component-library/FeedbackBox';
import { spacing } from '../../utils/styleguide';
import StepLogin from './Login';
import StepSignUpEmail from './SignUp';
import firebaseApp from '../../utils/firebase';
import { sendEmailVerification } from '../../utils/api';
import { useAuth } from '../../utils/authContext';
import { css } from '@emotion/react';

const TopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const FeedbackButton = styled(PrimaryButton)`
  display: block;
  margin: ${spacing[3]}px auto 0;
`;

const ALL_STEPS = ['login', 'sign-up'] as const;
type Step = (typeof ALL_STEPS)[number];
const isStep = (step?: string): step is Step => ALL_STEPS.includes(step as Step);

const LoginPage = () => {
  const router = useRouter();
  const { error, unverifiedUser } = useAuth();

  const [step, setStep] = useState<Step>('login');
  const [email, setEmail] = useState<string | undefined>();
  const [showUserFeedbackError, setShowUserFeedbackError] = useState(true);
  const [showUnverifiedEmailFeedbackError, setShowUnverifiedEmailFeedbackError] = useState(false);
  const [resendEmailStatus, setResendEmailStatus] = useState<'sending' | 'sent' | 'error'>();

  useEffect(() => {
    const queryStep = Array.isArray(router.query.step) ? router.query.step[0] : router.query.step;
    const queryEmail = Array.isArray(router.query.email)
      ? router.query.email[0]
      : router.query.email;
    const queryReturnUrl = Array.isArray(router.query.returnUrl)
      ? router.query.returnUrl[0]
      : router.query.returnUrl;

    setStep(isStep(queryStep) ? (queryStep as Step) : 'login');
    setEmail(decodeURIComponent(queryEmail || ''));

    // If a return URL is provided, then store it in local storage, to be used by the
    // fetch user action, which on success redirects the authenticated user to the url
    if (queryReturnUrl) {
      localStorage.setItem('@Auth:returnUrl', decodeURIComponent(queryReturnUrl));
    }
  }, [router.query]);

  useEffect(
    function resetShowError() {
      setShowUserFeedbackError(true);
    },
    [error],
  );

  useEffect(
    function resetShowError() {
      setShowUnverifiedEmailFeedbackError(!!unverifiedUser);
    },
    [unverifiedUser],
  );

  useOnMountEffect(() => {
    sendEvent({
      name: EVENT_NAMES.loginViewPage,
    });
  });

  useScrollToError();

  return (
    <Container
      css={css`
        margin: ${spacing[4]}px 0;
      `}
    >
      {showUserFeedbackError && error ? (
        <Row>
          <Col desktop="4 / span 6" tablet="3 / span 8">
            <TopContainer>
              <div>
                <ErrorBox
                  message="Oops! Something went wrong, try again."
                  onClose={() => setShowUserFeedbackError(false)}
                />
                <PrimaryButton href="/">Back to Home</PrimaryButton>
              </div>
            </TopContainer>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col desktop="4 / span 6" tablet="3 / span 8">
          <Paper>
            <div>
              {step === 'login' && (
                <>
                  <StepLogin email={email ?? ''} setEmail={setEmail} />
                </>
              )}
              {step === 'sign-up' && (
                <>
                  <StepSignUpEmail email={email ?? ''} setEmail={setEmail} />
                </>
              )}
            </div>
          </Paper>
        </Col>
      </Row>

      <SimpleModal
        open={showUnverifiedEmailFeedbackError}
        onClose={() => setShowUnverifiedEmailFeedbackError(false)}
        header="Please verify your email"
        body={
          <>
            <Typography variant="bodySmall">
              Check your inbox for an email containing the verification link and continue from
              there.
            </Typography>
            {resendEmailStatus === 'error' && (
              <FeedbackBox type="error">Please retry in a little while</FeedbackBox>
            )}
            <FeedbackButton
              variant="outline"
              onClick={async () => {
                setResendEmailStatus('sending');
                try {
                  const auth = getAuth(firebaseApp);
                  const token = await auth.currentUser?.getIdToken();
                  const email = auth.currentUser?.email;
                  if (!email) {
                    return;
                  }
                  await sendEmailVerification(token, window.location.href);
                  setResendEmailStatus('sent');
                } catch (e) {
                  setResendEmailStatus('error');
                }
              }}
              loading={resendEmailStatus === 'sending'}
              disabled={resendEmailStatus === 'sent'}
              trailingIconName={resendEmailStatus === 'sent' ? 'check' : undefined}
            >
              Resend email
            </FeedbackButton>
          </>
        }
      />
    </Container>
  );
};

const useScrollToError = () => {
  const { unverifiedUser, error } = useAuth();

  useEffect(
    function scrollToTopOnError() {
      if (error) {
        window.scrollTo({
          top: 0,
        });
      }
    },
    [error, unverifiedUser],
  );
};

export default LoginPage;
