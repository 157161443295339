import { client } from '../galaxyClient';

export function contentfulLog(env: string, token: string, args: Record<string, unknown>) {
  client()
    .tools.POST('/v2/contentful-log/', {
      body: {
        env,
        token,
        args,
      },
    })
    .catch(() => {});
}
