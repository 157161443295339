import { spacing } from '../../../utils/styleguide';
import Typography from '../../../common-components/component-library/text/Typography';
import { css } from '@emotion/react';

export default function Header({ title }: { title: string }) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[1]}px;
        margin-bottom: ${spacing[3]}px;
      `}
    >
      <Typography variant="h5">{title}</Typography>
    </div>
  );
}
