import styled from '@emotion/styled';
import ProviderButton from '../../common-components/component-library/buttons/ProviderButton';
import Typography from '../../common-components/component-library/text/Typography';
import { breakpoints, colors, spacing } from '../../utils/styleguide';
import SocialLoginButton from '../../common-components/component-library/buttons/SocialLoginButton';
import StarsIcon from '../../assets/svg/stars.svg';
import LockIcon from '../../assets/svg/lock-filled.svg';
import { css } from '@emotion/react';

const ProviderLoginSection = styled.div`
  margin-top: ${spacing[5]}px;
  text-align: center;
`;

const ProviderLoginOption = styled.div`
  display: grid;
  flex-direction: column;
  align-items: center;
  row-gap: ${spacing[3]}px;
  padding: ${spacing[3]}px 0;
  ${breakpoints.desktop} {
    padding: ${spacing[4]}px 0;
  }
`;

const ExternalProviderButton = styled(ProviderButton)`
  width: 100%;
`;

export type EmailAuthenticationMethod = 'login-link' | 'password';

type AlternativeLoginProvidersProps = {
  type: 'login' | 'signup';
  emailAuthenticationMethod?: EmailAuthenticationMethod;
  setEmailAuthenticationMethod?: (method: EmailAuthenticationMethod) => void;
};

const COPY = {
  login: 'log in',
  signup: 'sign up',
} as const;

const AlternativeLoginProviders = ({
  type,
  emailAuthenticationMethod,
  setEmailAuthenticationMethod,
}: AlternativeLoginProvidersProps) => {
  const copy = COPY[type];
  return (
    <ProviderLoginSection>
      <div
        css={css`
          display: grid;
          align-items: center;
          row-gap: ${spacing[1]}px;
        `}
      >
        <Typography variant="bodySmall" color={colors.darkGray}>
          OR
        </Typography>
      </div>
      <ProviderLoginOption>
        {emailAuthenticationMethod &&
          setEmailAuthenticationMethod &&
          (emailAuthenticationMethod === 'login-link' ? (
            <SocialLoginButton
              label="use a login link"
              logo={<StarsIcon height={24} width={20} />}
              onClick={() => {
                setEmailAuthenticationMethod('login-link');
              }}
            />
          ) : (
            <SocialLoginButton
              label="Password"
              logo={<LockIcon height={24} width={20} />}
              onClick={() => {
                setEmailAuthenticationMethod('password');
              }}
            />
          ))}
        <ExternalProviderButton
          providerName="Google"
          label={`${copy} with Google`}
          ariaLabel={`${copy} with Google`}
        />
        <ExternalProviderButton
          providerName="Facebook"
          label={`${copy} with Facebook`}
          ariaLabel={`${copy} with Facebook`}
        />
      </ProviderLoginOption>
    </ProviderLoginSection>
  );
};

export default AlternativeLoginProviders;
